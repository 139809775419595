.box {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-size: cover;
    background-image: url(https://img02.mockplus.cn/image/2021-08-24/5468a6a0-04e1-11ec-8bb5-11d88c32bc24.png);

    h1::before {
        content: '';
        display: block;
        height: 5rem;
        margin: 1rem;

        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(https://img02.mockplus.cn/image/2021-08-24/a0cb8d50-04e1-11ec-ba9c-17a3cfbdfcc5.png);
    }
}
