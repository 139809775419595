.container {
    background: rgb(248, 248, 248);
    transform: translateY(-24px);
    height: 100%;
    p {
        margin: 0;
    }
    h3 {
        font-size: 14px;
        padding: 12px;
        margin: 0;
        border-bottom: 1px solid rgb(238, 238, 238);
        display: flex;
        &:before {
            margin-right: 4px;
            content: ' ';
            display: block;
            width: 4px;
            height: 16px;
            background: linear-gradient(
                -14.0362deg,
                rgb(62, 80, 247) 0%,
                rgb(20, 133, 254) 100%
            );
            border-radius: 2px;
        }
    }
    .card {
        border-radius: 8.5px;
        height: 160px;
        display: flex;
        padding: 32px;
        justify-content: space-between;
        align-items: center;
        .info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: #fff;
            span {
                font-size: 16px;
            }
            p {
                transform: translateY(14px);
                font-size: 40px;
            }
        }
        .icon {
            i {
                font-size: 112px !important;
                color: rgb(142, 172, 228);
            }
        }
    }
    :global {
        .row {
            &:nth-of-type(1) {
                > div {
                    &:nth-of-type(1) > div {
                        background: linear-gradient(
                            -67.1663deg,
                            rgb(111, 134, 214) 1.5%,
                            rgb(72, 198, 239) 100%
                        );
                    }
                    &:nth-of-type(2) > div {
                        background: linear-gradient(
                            -67.1663deg,
                            rgb(255, 123, 87) 1.5%,
                            rgb(245, 205, 16) 100%
                        );
                        i {
                            color: rgb(255, 165, 124);
                        }
                    }
                    &:nth-of-type(3) > div {
                        
                        background: linear-gradient(
                            -67.1663deg,
                            rgb(32, 183, 160) 1.5%,
                            rgb(16, 168, 245) 100%
                        );
                        i {
                            color: rgb(101, 198, 194);
                            transform: translateY(-8px);
                        
                            font-size: 90px !important;
                        }
                    }
                }
            }
        }
    }
    .overviewCard {
        border-radius: 8.5px;
        background: #fff;
        margin-top: 24px;

        .overviewCardContent {
            height: 220px;
            display: flex;
            justify-content: center;
            align-items: center;
            .overviewCardContentRow {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                & + .overviewCardContentRow {
                    margin-left: 40px;
                }
                p {
                    font-size: 50px;
                    color: rgb(20, 133, 254);
                }
                span {
                    font-size: 16px;
                    color: rgb(51, 51, 51);
                }
            }
        }
    }
    .entry {
        height: 184px;
        background: #fff;
        margin-top: 24px;
        ul,
        li {
            list-style: none;
        }
        .entryContent {
            padding: 20px 40px;
            height: 138px;
            display: flex;
            align-items: center;
            li {
                cursor: pointer;
                font-size: 16px;
                color: rgb(51, 51, 51);
                display: flex;
                justify-content: center;
                flex-direction: column;
                a{
                    background-color: initial;
                    color: initial;
                    border-color: initial;
                    border: 0;
                    &:hover{
                        background-color: #138496;
                        color: #fff;
                    }
                }
               
                i {
                    font-size: 30px !important;
                    text-align: center;
                }
                & + li {
                    margin-left: 50px;
                }
            }
        }
    }
}
